import React from 'react'
import { map, identity } from 'lodash'

import { Grid } from '@vx/grid'
import { Group } from '@vx/group'
import { AxisLeft, AxisBottom } from '@vx/axis'
import { scaleLinear } from '@vx/scale'
import { LinePath } from '@vx/shape'
import { curveLinear } from '@vx/curve'
import { withParentSize } from '@vx/responsive'
import {
  WithTooltip, Tooltip,
  Line as TooltipLine,
  Sensor as TooltipSensor,
  Content as TooltipContent
} from './Tooltip'

import {
  tickLabelLeft,
  tickLabelBottom,
  numTicksFromWidth,
  numTicksFromHeight,
  gray,
  getColorsMap,
  minmax,
  axisLabelStyle
} from './misc'

class Lines extends React.PureComponent {
  static defaultProps = {
    margin: {
      left: 100,
      right: 20,
      top: 10,
      bottom: 70,
      dy: '0.25em',
    }
  }

  svg = React.createRef()

  render() {
    const {
      margin,
      series,
      parentWidth: width,
      parentHeight: height,
      leftLabel,
      bottomLabel
    } = this.props

    if (!width || !height) return null

    const colors = getColorsMap(series)

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const xScale = scaleLinear({
      domain: minmax(series, 'x'),
      range: [0, xMax]
    })

    const yScale = scaleLinear({
      domain: [0, minmax(series, 'y')[1]],
      range: [yMax, 0]
    })

    const x = (d) => xScale(d.x)
    const y = (d) => yScale(d.y)

    const numXTicks = numTicksFromWidth(width)
    const numYTicks = numTicksFromHeight(height)

    return (
      <WithTooltip
        series={ series }
        xScale={ xScale }
        margin={ margin }
        getSvg={ () => this.svg.current }
        parentWidth={ width }
        parentHeight={ height }
      >{ (tooltipProps) => (
        <>
          <svg
            width={ width }
            height={ height }
            ref={ this.svg }
          >
            <AxisLeft
              top={ margin.top }
              left={ margin.left }
              scale={ yScale }
              numTicks={ numYTicks }
              tickFormat={ d => d.toFixed(2) }
              tickLabelProps={ tickLabelLeft }
              stroke={ gray }
              hideTicks
              label={ leftLabel }
              labelProps={ axisLabelStyle }
              labelOffset={ 50 }
            />

            <AxisBottom
              top={ height - margin.bottom }
              left={ margin.left }
              scale={ xScale }
              tickLabelProps={ tickLabelBottom }
              tickFormat={ identity }
              stroke={ gray }
              hideTicks
              label={ bottomLabel }
              labelProps={ axisLabelStyle }
              labelOffset={ 20 }
            />

            <Group top={ margin.top } left={ margin.left }>
              <Grid
                stroke="#ddd"
                xScale={ xScale }
                yScale={ yScale }
                width={ xMax }
                height={ yMax }
                numTicksRows={ numYTicks }
                numTicksColumns={ numXTicks }
              />

              { map(series, ({ label, data }) => (
                <LinePath
                  key={ label }
                  data={ data }
                  x={ x }
                  y={ y }
                  curve={ curveLinear }
                  stroke={ colors[label] }
                  strokeWidth={ 2 }
                />
              )) }

              <TooltipLine { ...tooltipProps } yMax={ yMax } />

              <TooltipSensor { ...tooltipProps } xMax={ xMax } yMax={ yMax } />
            </Group>
          </svg>
          <Tooltip
            { ...tooltipProps }
            margin={ margin }
            xMax={ xMax }
            yMax={ yMax }
          >
            <TooltipContent { ...tooltipProps } colors={ colors } />
          </Tooltip>
        </>
      ) }</WithTooltip>
    )
  }
}

export default withParentSize(Lines)
