import React, {useEffect, useState} from 'react';
import {getAuth} from "firebase/auth";

const remediesModifiedLabelByIssue = (issue) => {
    switch (issue) {
        case "DUPL (same Pipe ID, same DOB, diff Break ID)":
        case "DUPL (same Pipe ID, same DOB, same Break ID)":
        case "DUPL (same Pipe ID, diff DOB, same Break ID)":
        case "DUPL (diff Pipe ID, same DOB, same Break ID)":
        case "DUPL (diff Pipe ID, diff DOB, same Break ID)":
            return "Breaks Deleted"
        default:
            return "Records Modified"
    }
}

const OverwrittenComponent = ({data}) => {
    const {type, content, href} = data;
    switch (type) {
        case 'redirect':
            return <span className="p-3">
                Remedy Overwritten, please refer to:
                <a className="ml-2" href={`/projects/${Api.getProjectId()}${href}`}>
                    <button className="btn-small btn-primary infraplan_button-small">
                    {content}
                    </button>
                </a>
            </span>
        default:
            return null;
    }
}

const Remedies = ({issue, issueName}) => {
    const [remedies, setRemedies] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const handleApply = async (remedyId) => {
        await Api.post(`projects/${Api.getProjectId()}/remedies/${remedyId}/apply`)
    }

    const handleRevert = async (remedyId) => {
        await Api.post(`projects/${Api.getProjectId()}/remedies/${remedyId}/revert`)
        toastr.success('Remedy was reverted.')
    }

    useEffect(() => {
        setLoading(true)
        Api.get(`projects/${Api.getProjectId()}/issues/${issue}/remedies`)
            .then(response => {
                if (response.disabled) {
                    setDisabled(true)
                }
                               setRemedies(response)
            })
            .finally(() => setLoading(false))
    }, [])

    if (disabled) return null;

    return (
        <div className="cell">
            <h3 className="title">
               Remedies
            </h3>
        <div>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Created by</th>
                    <th>Applied</th>
                    <th>{remediesModifiedLabelByIssue(issueName)}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {loading && <div>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
                {remedies?.map((rem) => (
                    <tr key={rem.id}>
                        <td>
                            {rem.builtin ? (
                                rem.name
                            ) : (
                                <a href="#" onClick={() => console.log(rem)}>
                                    {rem.name}
                                </a>
                            )}
                        </td>
                        <td>{rem.builtin ? "Builtin" : rem.user?.fullName || "--"}</td>
                        <td>{rem.appliedAt ? (new Date(rem.appliedAt)).toLocaleString() : "--"}</td>
                        <td>
                            {rem && rem.affectedCount || 0}
                        </td>
                        <td>
                            {!rem.appliedAt ? (
                                <button
                                    className="btn btn-primary with-progress"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you want to apply this remedy?")) {
                                            handleApply(rem.id)
                                                .then(() => location.reload())
                                        }
                                    }}
                                >
                                    Apply
                                </button>
                            ) : rem.revertible && (
                                <button
                                    className="btn btn-primary with-progress"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you want to revert this remedy?")) {
                                            handleRevert(rem.id)
                                                .then(() => location.reload())
                                        }
                                    }}
                                >
                                    Revert
                                </button>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {!remedies?.find(rem => rem.builtin) &&
                <div className="pt-2">
                    <a href={`${window.location.href}/remedies/new`}>

                        <button
                            className="btn btn-sm btn-primary infraplan_button_small"
                        >
                            <i className="add icon"></i>
                            Create rule
                        </button>
                    </a>
                </div>
            }
        </div>
        </div>
    );
}

export default Remedies;