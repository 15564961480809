import React, {useEffect} from "react";
import {useState} from "react";

const taskLabels = {
    "downloadPipes": {
        compiling: "Compiling Pipes for download",
        length: "Pipes in download: "
    },
    "downloadBreaks": {
        compiling: "Compiling Breaks for download",
        length: "Breaks in download: "
    },
}


const Tasks = ({projectId}) => {
    const [tasks, setTasks] = useState([]);
    const [canExit, setCanExit] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        const fetchTasks = () => {
            Api.get(`projects/${projectId}/tasks`)
                .then((data) => {
                    console.log(data)
                    setTasks(data);
                    setLoading(false);
                })
        };

        const intervalId = setInterval(fetchTasks, 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (tasks && tasks.length > 0) {
            const runningTasks = tasks.filter(task => task.state === 'running' || task.state === 'pending');
            if (runningTasks.length === 0) {
                setCanExit(true);
            }
        }
    }, [tasks])

    const containerCenterOfScreenStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '80%',
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
        zIndex: 1000
    }

    const TaskRender = ({task}) => {
        if (task.state === 'errored') {
            return (
                <div className="card bordered p-2">
                    <div className="d-flex justify-content-between">
                        <div
                            style={{fontSize: '1.2em'}}
                        ><b>{taskLabels[task.taskType].compiling}</b> - {task.state}</div>
                        <div>
                            Errored at {(new Date(task.endTs)).toLocaleDateString()}
                        </div>
                    </div>
                </div>
            )
        }
        if (task.state === 'completed') {
            switch (task.taskType) {
                case 'downloadPipes':
                case 'downloadBreaks':
                    return (
                        <div className="card bordered p-2">
                            <div className="d-flex justify-content-between">

                                <div
                                    style={{fontSize: '1.2em'}}
                                ><b>{taskLabels[task.taskType].compiling}</b> - {task.state}</div>
                                <div>
                                    Completed at {(new Date(task.endTs)).toLocaleString()}
                                </div>
                            </div>
                                {task.payload.length && <div>{taskLabels[task.taskType].length + (task.payload.length - 1)}</div>}
                            <button
                                onClick={() => {
                                    Api.download(`projects/${projectId}/tasks/${task.id}/download`)
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = task.payload.filename.split("/")[1];
                                            document.body.appendChild(a);
                                            a.click();
                                            a.remove();
                                        })
                                        .catch(error => console.error('Error downloading file:', error));
                                }}
                            >Download File
                            </button>
                        </div>
                    )
                default:
                    return (
                        <div className="card bordered p-2">
                            <div
                                style={{fontSize: '1.2em'}}
                            ><b>{taskLabels[task.taskType]}</b> - {task.state}</div>
                            Completed at {task.endTs}
                        </div>
                    )
            }
        }

        return (
            <div className="card bordered p-2">
                <div
                    style={{fontSize: '1.2em'}}
                ><b>{taskLabels[task.taskType].compiling}</b> - {task.state}</div>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                         aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{
                        width: task.statePercentage + '%'
                    }}></div>
                </div>
            </div>
        )
    }

    return (
        <div style={containerCenterOfScreenStyle}>
            <h2>Running and Previous tasks</h2>
            <div className="d-flex flex-column gap-2" style={{gap: '10px'}}>
                {
                    tasks && tasks.map((task, index) => (
                        <TaskRender task={task}/>
                    ))
                }
            </div>
            {
                loading && <div
                    className="w-100 text-center"
                >
                    <h2>Loading</h2>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
        </div>
    )

}

export default Tasks;