import React, {
  useState, useRef, useCallback, useMemo, useEffect
} from 'react'
import {
  map, transform, keys, each, filter, includes, sortBy
} from 'lodash'
import StackedAreaAndLines from '../Chart/StackedAreaAndLines'
import Legend from './Legend'
import { CSVLink } from 'react-csv'

const getUniqueCohorts = (data) => keys(
  transform(
    data,
    (_cohorts, { length_to_replace_per_cohort }) => each(
      length_to_replace_per_cohort,
      (_length, cohort) => {
        if (!_cohorts[cohort]) _cohorts[cohort] = 1 // eslint-disable-line no-param-reassign
      }
    ),
    {}
  )
)

const getLengthSeries = (data, colors) => {
  const cohorts = getUniqueCohorts(data)

  return map(
    transform(
      data,
      (result, { year, length_to_replace_per_cohort }) => {
        each(cohorts, (cohort) => {
          result[cohort] = result[cohort] || [] // eslint-disable-line no-param-reassign
          result[cohort].push({
            x: year,
            y: (length_to_replace_per_cohort[cohort] || 0)
          })
        })
      },
      {}
    ),
    (points, cohort) => ({
      label: cohort,
      id: cohort,
      data: points,
      color: colors[cohort]
    })
  )
}

const getBRSeries = ({data, measures}) => {
  const br_points = []
  const bre_points = []
  const do_nothing_br_points = []
  const do_nothing_bre_points = []
  const cost_points = []
  const replacement_rate_points = []

  each(data, ({ year, br, bre, do_nothing_br, do_nothing_bre, cost, replacement_rate }) => {
    br_points.push({ x: year, y: br })
    bre_points.push({ x: year, y: bre })
    do_nothing_br_points.push({ x: year, y: do_nothing_br })
    do_nothing_bre_points.push({ x: year, y: do_nothing_bre })
    cost_points.push({ x: year, y: cost })
    replacement_rate_points.push({ x: year, y: replacement_rate })
  })

  let visibleMeasures = [
    {
      label: <span>BRE <br />Do-Nothing</span>,
      id: 'do_nothing_bre',
      data: do_nothing_bre_points,
      color: '#000',
      dasharray: '6,6',
      default: false
    },
    {
      label: <span>BRE <br />Resulting</span>,
      id: 'bre',
      data: bre_points,
      color: '#000',
      dasharray: '4,0',
      default: true
    },
    {
      label: <span>Break Rate <br />Do-Nothing</span>,
      id: 'do_nothing_br',
      data: do_nothing_br_points,
      color: '#000',
      dasharray: '6,6',
      default: false
    },
    {
      label: <span>Break Rate <br />Resulting</span>,
      id: 'br',
      data: br_points,
      color: '#000',
      dasharray: '4,0',
      default: true
    },
    {
      label: <span>Cost</span>,
      id: 'cost',
      data: cost_points,
      color: '#000',
      dasharray: '4,0',
      default: false
    },
    {
      label: <span>Replacement Rate</span>,
      id: 'replacement_rate',
      data: replacement_rate_points,
      color: '#000',
      dasharray: '4,0',
      default: false
    }
  ]

  if(measures && measures instanceof Array) {
    visibleMeasures = visibleMeasures.filter(m => measures.indexOf(m.id) >= 0);
    for(let m of visibleMeasures) {
      m.default = true;
    }
  }

  return visibleMeasures;
}

export default ({ downloadUrl, data, colors, title, measures, available_cohorts, linesLabel = "Break Rate (Nb Breaks/mi/yr)" }) => {

  const chart = useRef()
  const [tooltipData, setTooltipData] = useState({})

  const allBRSeries = useMemo(() => getBRSeries({data: data.series, measures}), [data])
  const allLengthSeries = useMemo(() => sortBy(getLengthSeries(data.series, colors), ({ label }) => label), [data, colors])

  const [selectedBRLabels, setSelectedBRLabels] = useState(map(filter(allBRSeries, 'default'), 'id'))
  const [selectedLengthLabels, setSelectedLengthLabels] = useState(map(allLengthSeries, 'id'))

  useEffect(() => {
    setSelectedLengthLabels(map(allLengthSeries, 'id'))
  }, [allLengthSeries])

  const renderTooltip = useCallback((props) => {
    const newData = props.open ? props.data : null

    if (newData !== tooltipData) {
      setTooltipData(newData)
    }

    return null
  }, [])

  const selectedBRSeries = useMemo(() => (
    filter(allBRSeries, ({ id }) => includes(selectedBRLabels, id))
  ), [selectedBRLabels, allBRSeries])

  const selectedLengthSeries = useMemo(() => (
    filter(allLengthSeries, ({ id }) => includes(selectedLengthLabels, id))
  ), [selectedLengthLabels, allLengthSeries])

  const downloadChart = useCallback((type) => {
    const svg = chart.current

    if (!svg) { return }

    window.downloadSvg(svg, { type })
  }, [chart])
  const downloadSvg = useCallback((e) => { e.preventDefault(); downloadChart('svg') }, [downloadChart])
  const downloadPng = useCallback((e) => { e.preventDefault(); downloadChart('png') }, [downloadChart])

  return (
    <>
      <div>
        <div className="btn-group pull-right">
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={ downloadPng }
          >Download</button>

          <button type="button" className="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="sr-only">Toggle Dropdown</span>
          </button>

          <div className="dropdown-menu dropdown-menu-right">
            <a
              href="#"
              className="dropdown-item"
              onClick={ downloadPng }
            >Chart (PNG)</a>

            <a
              href="#"
              className="dropdown-item"
              onClick={ downloadSvg }
            >Chart (SVG)</a>

            {data.pipe_replacement_schedule &&
              <CSVLink
                data={data.pipe_replacement_schedule}
                className="dropdown-item"
                headers={["Pipe ID", "Replacement Year", "Length", "PBR at time of Replacement", "Date of Installation", "Material", "Diameter", "Previous Breaks"]}
                filename={`pipe_replacement_schedule.csv`}
              >Pipe Replacement Schedule (CSV)</CSVLink>
            }
          </div>
        </div>

        <h5>{title}</h5>
      </div>

      <div className="plan-withLegend">
        <div className="plan-withLegend-legend plan-withLegend-legend-left">
          <Legend
            series={ allBRSeries }
            selected={ selectedBRLabels }
            setSelected={ setSelectedBRLabels }
            currentData={ tooltipData }
            colors={ colors }
          />
        </div>
        <div className="plan-withLegend-chart" style={{ height: 500 }}>
          <StackedAreaAndLines
            ref={ chart }
            areas={ selectedLengthSeries }
            lines={ selectedBRSeries }
            areasLabel="Length to replace (mi)"
            linesLabel={ linesLabel }
            bottomLabel="Year"
            renderTooltip={ renderTooltip }
            showLinesLegendsLines={ false }
          />
        </div>
        <div className="plan-withLegend-legend plan-withLegend-legend-right">
          <Legend
            series={ allLengthSeries }
            selected={ selectedLengthLabels }
            setSelected={ setSelectedLengthLabels }
            currentData={ tooltipData }
            colors={ colors }
            showToggleAll
          />
        </div>
      </div>
    </>
  )
}
